var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery-folder-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8"}},[_c('intract-smart-list',{ref:"gallery-folder-smart-list",staticClass:"mt-2 mx-2",attrs:{"paginated":"","endpoint":_vm.galleryFolderEndpoint,"disable-search":""},on:{"updateAllItemsList":(l) => (_vm.galleryPosts = l)},scopedSlots:_vm._u([{key:"list",fn:function({ itemsList: posts }){return [_c('v-row',{staticClass:"mt-2 mb-2",attrs:{"dense":""}},_vm._l((posts),function(post){return _c('v-col',{key:post.id,attrs:{"cols":"4","md":"3"}},[(post.image_file)?_c('v-img',{staticClass:"cursor-pointer",attrs:{"src":post.image_file.document,"aspect-ratio":"1"},on:{"click":function($event){return _vm.$router.push({
                    name: 'GalleryPost',
                    params: { postId: post.id },
                  })}}}):(post.video_file)?_c('div',{staticClass:"video-container"},[_c('v-img',{staticClass:"cursor-pointer",attrs:{"src":"https://www.pngkit.com/png/full/267-2678423_bacteria-video-thumbnail-default.png","aspect-ratio":"1","cover":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'GalleryPost',
                      params: { postId: post.id },
                    })}}},[_c('div',{staticClass:"image-icon"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"80px","color":"white"}},[_vm._v("mdi-play")])],1)],1)])],1):(post.video_link)?_c('div',{staticClass:"video-container"},[_c('v-img',{staticClass:"cursor-pointer",attrs:{"src":_vm.Helper.getYouTubeThumbnailURL(post.video_link),"aspect-ratio":"1","cover":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'GalleryPost',
                      params: { postId: post.id },
                    })}}},[_c('div',{staticClass:"image-icon"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"80px","color":"white"}},[_vm._v("mdi-play")])],1)],1)])],1):(post.is_folder)?_c('div',{staticClass:"folder-container fill-height"},[_c('v-card',{staticClass:"fill-height py-0",on:{"click":function($event){return _vm.$router.push({
                      name: 'GalleryFolder',
                      params: { folderId: post.id },
                    })}}},[_c('v-card-text',{staticClass:"py-0 d-flex justify-center align-center fill-height"},[_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"5em"}},[_vm._v("mdi-folder-multiple-image")]),_c('p',{staticClass:"ma-0 mt-1 font-weight-medium folder-title"},[_vm._v(" "+_vm._s(post.caption)+" ")])],1)])],1)],1):_vm._e()],1)}),1)]}}])})],1)],1),_c('intract-list-sheet',{attrs:{"title":"What do you want to post?","visible":_vm.showPostOptions,"list":_vm.postOptions},on:{"close":function($event){_vm.showPostOptions = false}}}),_c('intract-create-edit-sheet',{key:"gallery-create-image-sheet",staticClass:"gallery-create-image-sheet",attrs:{"persistent":"","title":"Create Image Post","description":"Upload image(s) and display in gallery","visible":_vm.createImagePostSheet.visible,"fields":_vm.createImagePostFormFields,"file-size-limit":5242880,"endpoint":_vm.endpoints.galleryPostViewSet,"custom-error-text":_vm.customImagePostValidation,"edit-id":_vm.createImagePostSheet.editId,"data-object":_vm.createImagePostSheet.obj,"create-success-message":_vm.createImagePostSheet.createSuccessMessage,"enable-media":""},on:{"close":function($event){_vm.createImagePostSheet.visible = false;
      _vm.createImagePostSheet.editId = null;},"objectCreated":_vm.imageCreated,"updateObject":(obj) => (_vm.createImagePostSheet.obj = obj)}}),_c('intract-create-edit-sheet',{key:"gallery-create-video-sheet",staticClass:"gallery-create-video-sheet",attrs:{"persistent":"","title":"Create Video Post","description":"Upload video content to the gallery","visible":_vm.createVideoPostSheet.visible,"fields":_vm.createVideoPostFormFields,"endpoint":_vm.endpoints.galleryPostViewSet,"edit-id":_vm.createVideoPostSheet.editId,"data-object":_vm.createVideoPostSheet.obj,"custom-error-text":_vm.customVideoPostValidationError,"create-success-message":_vm.createVideoPostSheet.createSuccessMessage,"enable-media":""},on:{"close":function($event){_vm.createVideoPostSheet.visible = false;
      _vm.createVideoPostSheet.editId = null;},"objectCreated":_vm.imageCreated,"updateObject":(obj) => (_vm.createVideoPostSheet.obj = obj)}}),_c('intract-create-edit-sheet',{key:"gallery-edit-folder-sheet",staticClass:"gallery-edit-folder-sheet",attrs:{"persistent":"","title":"Edit Folder","description":"Use folders to better organise your gallery","visible":_vm.editFolderSheet.visible,"fields":_vm.editFolderFormSheet,"endpoint":_vm.endpoints.galleryPostViewSet,"edit-id":_vm.editFolderSheet.editId,"data-object":_vm.editFolderSheet.obj,"edit-success-message":_vm.editFolderSheet.editSuccessMessage},on:{"close":function($event){_vm.editFolderSheet.visible = false;
      _vm.editFolderSheet.editId = null;},"objectCreated":_vm.folderEdited,"updateObject":(obj) => (_vm.editFolderSheet.obj = obj)}}),_c('confirmation-dialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Are you sure you want to delete this gallery folder?","description":"This will also delete all the images and videos residing inside this folder. This action cannot be undone.","delete-dialog":""},on:{"successCallback":_vm.deleteGalleryFolder,"failureCallback":function($event){_vm.showDeleteDialog = false}}}),_c('v-fab-transition',[(!_vm.isLoading && _vm.currentUser.is_admin)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.showPostOptions = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }